import axios from 'axios';
import jwt from 'jsonwebtoken';
import { useParams, useRouteMatch } from 'react-router';
import { useCallback, useContext, useEffect } from 'react';

import { AppContext } from '../containers/AppContext';

export const generatePubKey = async (app = 'repairy') => {
  const key = process.env.REACT_APP_API_PUBLIC_KEY
  const secret = process.env.REACT_APP_API_PUBLIC_KEY_SECRET
  const token = await jwt.sign({key}, secret, { expiresIn: '1d' })
  return token
}

export const getApi = async ({ baseURL }) => {
  const token = await generatePubKey();
  const instance = axios.create({
    baseURL,
    headers: {'X-App-Key': token}
  });
  return instance;
}

export const prepareApi = async () => {
  const token = await generatePubKey();
  axios.defaults.headers['X-App-Key'] = token;
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
}

export const AppName = process.env.REACT_APP_NAME;
export const reviewPrefix = legacy => legacy ? '/r' : '';
export const reviewPath = (code, page = '', legacy) => {
  const prefix = reviewPrefix(legacy);
  return `${prefix}/${code}/${page}`;
}

export const preparePubKey = async () => {
  const token = await generatePubKey();
  return token
}

export const useApi = () => {
  const {pubKey} = useContext(AppContext);
  const baseURL = process.env.REACT_APP_API_URL
  const instance = axios.create({
    baseURL,
    headers: {'X-App-Key': pubKey}
  });
  const api = useCallback(async (params) => {
    const result = await instance(params);
    return result;
  }, [instance]);
  return api;
}

export const referralPrefix = '/s';
export const referralPath = (code, page = '') => {
  return `${referralPrefix}/${code}/${page}`;
}

export const referralRedeemPrefix = '/v';
export const referralRedeemPath = (code, page = '') => {
  return `${referralRedeemPrefix}/${code}/${page}`;
}

// eslint-disable-next-line
export const PhonePattern = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

export const usePartnerTheme = () => {
  const { code } = useParams();
  const isReferralGreeting = useRouteMatch({
    exact: true,
    path: referralPath(code),
  });
  const isRedeemGreeting = useRouteMatch({
    exact: true,
    path: referralRedeemPath(code),
  });
  const isReviewGreeting = useRouteMatch({
    exact: true,
    path: reviewPath(code),
  });
  const isGreeting = (
    isReviewGreeting ||
    isRedeemGreeting ||
    isReferralGreeting
  );
  const { shop, setTheme } = useContext(AppContext);
  useEffect(() => {
    if (!shop || !shop.theme) return;
    const theme = shop.theme;
    const themeStyle = {
      color: theme.fontColor,
      backgroundColor: theme.bgColor,
      button: {
        color: theme.buttonTextColor,
        backgroundColor: theme.buttonBgColor,
      }
    };
    if (!isGreeting) {
      setTheme(themeStyle);
    }
  }, [shop, setTheme, isGreeting]);
}


export const RepairyBookingURL = process.env.REACT_APP_REPAIRY_BOOK_URL ||
  'https://book.repairy.com.au';

export const RepairyBookingV2URL = process.env.REACT_APP_REPAIRY_BOOK_V2_URL ||
  'https://book.repairy.au';

export const RepairyDashboardURL = process.env.REACT_APP_REPAIRY_DASHBOARD_URL ||
  'https://workshops.repairy.com.au';

export const RepairyDashboardV2URL = process.env.REACT_APP_REPAIRY_DASHBOARD_V2_URL ||
  'https://shops.repairy.com.au';
